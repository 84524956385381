import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import './App.css';
import { HomePage } from "Pages/Home";
import { ProductsPage } from "Pages/Products";
import { DigitalServicesPage } from "Pages/DigitalServices";
import { UnderConstructionPage } from "Pages/UnderConstruction";
import { AboutUs } from "Pages/AboutUs";
import { Contact } from "Pages/Contact";

import { NavBar } from "Components/Navbar";
import { Footer } from "Components/Footer";
import { SmartSystemsPage } from "Pages/SmartSystems";
import { OurOfferPage } from "Pages/OurOffer";


function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/digital-services" element={<DigitalServicesPage />} />
          <Route path="/smart-systems" element={<SmartSystemsPage />} />
          <Route path="/our-offer" element={<OurOfferPage />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
