import React from "react";

function Separator(props) {

    let style = {
        width : props.width || "100%",
        height : props.height || "1px",
        background : props.background || "red",
    }

    return (
        <hr className={`red-text-bottom ${props.className || ""}`} style={style}/>
    );
}

export { Separator };
