import React from 'react';

function Landing(props) {
    return (
        <div className={`container-fluid landing-bg ${props.className || ""}`} id={props.id || ""}>
            <div className="container">
                <div className="row align-items-center landing">
                    <div className="col-12 text-center">
                        <h1 className="page-header-title">{props.title}</h1>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

function LandingAction(props) {
    return (
        <div className={`container-fluid landing-action ${props.cname}`}>
            <div className="container">
                <div className="row align-items-center landing">
                    <div className="col-12 text-center">
                        <h5 className='subtitle'>{props.subtitle}</h5>
                        <h3>{props.title}</h3>
                        <hr className="red-separator" />
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Landing, LandingAction };