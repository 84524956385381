import { Landing, LandingAction } from "Components/Landing";
import React, { Fragment } from "react";

import { NavBar } from "Components/Navbar";
import { Footer } from "Components/Footer";
import { Separator } from "Components/Separator";

import scheme from "Assets/our_offer/Scheme@2x.png";



function LandingPage() {
    return (
        <Fragment>
            <Landing title="OUR OFFER" id="landing-our-offer">
                <p className="page-header-text mb-5">We offer a wide range of services to improve your process.</p>
            </Landing>
        </Fragment>
    )
}

function SecondLanding() {

    return (
        <Fragment>
            <div className="container-fluid text-center operating-cost radial-bg">
                <h3>TECHNOLOGY INTEGRATOR</h3>
                <Separator className="red-separator" width="5rem" height="2px" />
                <p className="mb-5">IPREXYS solutions integrate cutting-edge technologies to provide you with solutions to improve your efficiency and reduce your operating costs.
                </p>
                <img className="raas-scheme" src={scheme} alt="OPERATING COST REDUCTIONS" />
            </div>
        </Fragment>
    )
}

function ThirdLanding() {

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-6 infoBox" style={{ padding: "5rem 6rem" }}>
                        <h2>OUR OFFER</h2>
                        <Separator width="4rem" />
                        <p>In order to offer you the best possible service, we have thought <br />
                            about a reliable and sustainable process that includes :
                        </p>
                        <div className="row mt-5 pt-5 our-offer">
                            <div className="col-12 col-md-6">
                                <Separator width="5rem" height="2px" background="black" />
                                <h4>IMPROVE & SIMPLIFY</h4>
                                <p>Your processes</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <Separator width="5rem" height="2px" background="black" />
                                <h4>ENABLE DATA ANALYSIS</h4>
                                <p>Traceability and see trends</p>
                            </div>
                            <div className="col-12 col-md-6 mt-5">
                                <Separator width="5rem" height="2px" background="black" />
                                <h4>TAKE ADVANTAGE</h4>
                                <p>of industry 4.0.</p>
                            </div>
                            <div className="col-12 col-md-6 mt-5">
                                <Separator width="5rem" height="2px" background="black" />
                                <h4>MODULAR CONCEPT</h4>
                                <p>Flexible to your processes</p>
                            </div>
                            <div className="col-12 col-md-6 mt-5">
                                <Separator width="5rem" height="2px" background="black" />
                                <h4>QUALITY</h4>
                                <p>ASSURANCE</p>
                            </div>
                            <div className="col-12 col-md-6 mt-5">
                                <Separator width="5rem" height="2px" background="black" />
                                <h4>STANDARDIZED INTEGRATED</h4>
                                <p>Solutions ready-to-use</p>
                            </div>
                            <div className="col-12 col-md-6 mt-5">
                                <Separator width="5rem" height="2px" background="black" />
                                <h4>PROCESS</h4>
                                <p>REPEATABILITY</p>
                            </div>
                            <div className="col-12 col-md-6 mt-5">
                                <Separator width="5rem" height="2px" background="black" />
                                <h4>MODULAR CONCEPT</h4>
                                <p>Flexible to your processes</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6" style={{ padding: "5rem 0rem 0rem 3rem" }} >
                        <div className="our-offer-sideimage">
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function FourthLanding() {

    let style1 = {
        background: '#1C1C1C',
        color: 'white',
        padding: '5rem 10rem',
        width: '80%',
        margin: 'auto'
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="">
                    <div className="row justify-content-center mt-5 text-center" style={style1}>
                        <div className="col-12 mb-5">
                            <h2>OPERATING COST REDUCTION</h2>
                            <Separator className="center-separator" width="4rem" height="2px" />
                        </div>
                        <div className="col-4">
                            <h3 className="big-text">+90%</h3>
                            <p>PROCESS TIME REDUCTION</p>
                        </div>
                        <div className="col-4">
                            <h3 className="big-text">+80%</h3>
                            <p>TIME REDUCTION TO GENERATE QUALITY DOCUMENTATION</p>
                        </div>
                        <div className="col-4">
                            <h3 className="big-text">+95%</h3>
                            <p>MMHH REDUCTION</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function OurOfferPage() {
    return (
        <div>
            <NavBar />
            <LandingPage />
            <SecondLanding />
            <LandingAction title="OPERATING COST REDUCTION" subtitle="DIGITAL SERVICES" cname="landing-opc-reduc">
                <p>In order to support Continuous Process Improvement in real time, our all-inclusive services <br />are based in Artificial Intelligence engine, secured cloud platform and more.</p>
                <a href="/products" className="landing-btn fill">LEARN MORE</a>
            </LandingAction>
            <ThirdLanding />
            <FourthLanding />
            <Footer />
        </div>
    );
}

export { OurOfferPage };