import React from "react";
import logo from '../../Assets/logo_nav.png';

function NavBar() {

    return (
        <nav className="navbar navbar-expand-lg site-header sticky-top navbar-dark bg-dark">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="Univ-Evry Logo" className={"img-fluid logo"} />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link un dropdown-toggle" href="/about-us" id="navbarDropdownMenuAboutUs" role="button" aria-expanded="false">
                                About
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuAboutUs">
                                <li><a className="dropdown-item" href="/about-us#who-we-are">Who whe are</a></li>
                                <li><a className="dropdown-item" href="/about-us#our-values">Values</a></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link un dropdown-toggle" href="/products" id="navbarDropdownMenuProducts" role="button" aria-expanded="false">
                                Products
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuProducts">
                                <li><a className="dropdown-item" href="/products#piping">Piping inspector</a></li>
                                <li><a className="dropdown-item" href="/products#pressure">Pressure test</a></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link un dropdown-toggle" href="/digital-services" id="navbarDropdownMenuRaaS" role="button" aria-expanded="false">
                                Digital services
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuRaaS">
                                <li><a className="dropdown-item" href="/digital-services#raas">Reporting as a Service</a></li>
                            </ul>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link un dropdown-toggle" href="/smart-systems" id="navbarDropdownMenuSmartS" role="button" aria-expanded="false">
                                Smart Systems
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuSmartS">
                                <li><a className="dropdown-item" href="/smart-systems#advantages">Advantages</a></li>
                                <li><a className="dropdown-item" href="/smart-systems#features">Features</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link un" href="/contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export { NavBar };
