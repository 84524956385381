import { Landing, LandingAction } from "../../Components/Landing";
import React, { Fragment } from "react";
import { NavBar } from "Components/Navbar";
import { Footer } from "Components/Footer";
import { Separator } from "Components/Separator";

function LandingPage() {
    return (
        <Fragment>
            <Landing title="ABOUT US" id="landing-about-us">
                <p className="page-header-text mb-5">We are more than just a company</p>
            </Landing>
        </Fragment>
    )
}

function SecondLanding() {

    return (
        <Fragment>
            <div className="container-fluid" style={{ marginTop: "5rem", marginBottom: "5rem" }} id="who-we-are">
                <div className="row">
                    <div className="col-sm-12 col-md-6 infoBox">
                        <h2>WHO WE ARE</h2>
                        <Separator width="5rem" height=".15rem" />
                        <p>France based innovating Startup in the automation sector with special focus on Industry 4.0 to combine the best of both Industrial and Digital technologies.</p>
                    </div>
                    <div className="col-sm-12 col-md-6 about-us-bloc about-us-bloc1">
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 about-us-bloc about-us-bloc2">
                    </div>
                    <div className="col-sm-12 col-md-6 infoBox">
                        <h2>OUR MISSION</h2>
                        <Separator width="4rem" height=".15rem" />
                        <p>Provide innovating solutions to improve efficiency, reduce operating costs and environmental footprint for our customers to support them on their Digital Transformation.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 infoBox">
                        <h2>OUR VISION</h2>
                        <Separator width="4rem" height=".15rem" />
                        <p>Improve industrial processes through <br /> meaningful innovations.</p>
                    </div>
                    <div className="col-sm-12 col-md-6 about-us-bloc about-us-bloc3">
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function OurValues() {
    return (
        <Fragment>
            <div className="container-fluid" style={{ marginTop: '5rem', marginBottom: '5rem' }} id="our-values">
                <div className="row justify-content-center">
                    <div className="values text-center">
                        <h5>OUR VALUES</h5>
                        <hr className="red-separator" />

                        <h3>PEOPLE</h3>
                        <p>We truly believe in people's potential to create the best solutions for our customers not to reduce their workforce but to allow them to allocate their people on more productive tasks.</p>
                        <hr className="red-separator" />

                        <h3>INNOVATION</h3>
                        <p>The simultaneous pursuit of differentiation and low cost, creating a leap in value for our customers and our company.</p>

                        <hr className="red-separator" />

                        <h3>EFFICIENCY</h3>
                        <p>Obtaining the best possible outcome in the least wasteful manner by maintaining dynamic, best resources utilization and optimal procedures while consistently looking even better ways to develop our solutions.</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function AboutUs() {
    return (
        <div>
            <NavBar />
            <LandingPage />
            <SecondLanding />
            <LandingAction title="DISCOVER OUR TECHNOLOGIES" subtitle="SMART SYSTEM" cname="landing-smart-system">
                <p>We offer integrated technologies as single solutions that will impact significantly to improve your process in the following areas: Safety, Data, Environment, Operational Cost and Process.</p>
                <a href="/smart-systems" className="landing-btn fill">LEARN MORE</a>
            </LandingAction>
            <OurValues />
            <LandingAction title="SPARE PARTS" subtitle="PRODUCTS" cname="landing-replacement-parts">
                <p>In need of spare parts for your equipment? IPREXYS also offer spare part supply <br /> in the best time and price.  This additionally to our best-in-class Piping Inspectors and Smart Pressure Test consoles.</p>
                <a href="/products" className="landing-btn fill">SHOW OUR PRODUCTS</a>
            </LandingAction>
            <Footer />
        </div>
    );
}

export { AboutUs };