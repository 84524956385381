import React, { Fragment } from "react";

import { Landing, LandingAction } from "Components/Landing";
import { NavBar } from "Components/Navbar";
import { Footer } from "Components/Footer";
import { Separator } from "Components/Separator";

import ValvePressure from "Assets/smart_systems/GATE_VALVE_TESTBENCH.png"
import SmartConsolePTB from "Assets/our_products/smart_console_for_pressure_test_bay@2x.png"
import PipeworkInspector from "Assets/smart_systems/Straight_Pipe.png"
import ModularPressure from "Assets/our_products/modular_pressure_test_bay@2x.png"


function LandingPage() {
    return (
        <Fragment>
            <Landing title="SMART SYSTEMS" id="landing-smart-systems">
                <p className="page-header-text mb-5">Learn more about our advantages & features</p>
            </Landing>
        </Fragment>
    )
}

function Accordion() {

    return (
        <Fragment>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            SAFETY
                        </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <ul className="diamond-deco">
                                <li>95% risk reduction on handling activities</li>
                                <li>Personnel exposure reduction</li>
                                <li>Minimum competency required for operator</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            DATA
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <ul className="diamond-deco">
                                <li>No manual inputs nor manipulation</li>
                                <li>Additional parameters acquired</li>
                                <li>Consistency and repeatability</li>
                                <li>Cloud based architecture</li>
                                <li>Enabler for AI implementation (structured data)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            ENVIRONMENT
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <ul className="diamond-deco">
                                <li>Process water consumption reduction</li>
                                <li>CO2 Footprint reduction via energy consumption optimization</li>
                                <li>100% paperless process</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            COST SAVING
                        </button>
                    </h2>
                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <ul className="diamond-deco">
                                <li>Reusable test sealings</li>
                                <li>Damage reduction on sealing area for tested components</li>
                                <li>Personnel optimization for more productive tasks:</li>
                                <li className="notDiamond-deco">
                                    <ul className="diamond-deco">
                                        <li>Problem Solving</li>
                                        <li>Exception Handling</li>
                                        <li>Troubleshooting</li>
                                    </ul>
                                </li>
                                <li>Minimum TCO</li>
                                <li>Operational Cost Reduction</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            PROCESS
                        </button>
                    </h2>
                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <ul className="diamond-deco">
                                <li>Up to 95% process time improvement</li>
                                <li>Minimal Footprint</li>
                                <li>Fully standardized automated process</li>
                                <li>Equipment protection against corrosion</li>
                                <li>Seamless reporting flow between functions</li>
                                <li>Predefined automated test sequence based on CMMS status</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

function SecondLanding() {

    return (
        <Fragment>
            <div className="container-fluid" id="advantages">
                <div className="row">
                    <div className="col-12 infoBox" style={{ padding: '5rem 5rem 5px 5rem' }}>
                        <h3>SMART SYSTEM ADVANTAGES</h3>
                        <Separator width="5rem" height="2px" />
                        <p className="mb-5">Our systems are based on 5 key areas that will not only
                            facilitate your process, <br /> but will also significantly improve
                            your score on several topics and bring a new breath <br />
                            of technologies, modernity and efficiency. </p>
                    </div>
                    <div className="col-12 infoBox" style={{ padding: '0px 3rem 50px 3rem' }}>
                        <Accordion />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function ThirdLanding() {

    return (
        <div className="container-fluid landing-carousel mb-5 mt-3" id="features">
            <div className="row">
                <div className="col-12">
                    <div id="carousel-products-two" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="false">
                        <div className="carousel-inner">

                            <div className="carousel-item product-carousel-container active container-fluid d-flex" style={{ alignItems: "center", justifyContent: "center" }}>
                                <div className="row d-flex flex-lg-row flex-sm-column" style={{ padding: "0 10rem", alignItems: "center", justifyContent: "space-around", flexDirection: "row", flexWrap: "nowrap", alignContent: "center" }}>
                                    <div className="textcr slide-padding">                                        <h4>SMART SYSTEM FEATURES</h4>
                                        <h3 className="mb-5">Valve Pressure Testbench</h3>
                                        <ul>
                                            <li>Fully automated valve test sequence</li>
                                            <li>Pressure and Torque parameters acquired</li>
                                            <li>Integrated closed loop water recycling system</li>
                                            <li>Corrosion Inhibitor dosing mechanism</li>
                                            <li>Smart filling circuit</li>
                                            <li>Reusable test sealing mechanism (property design)</li>
                                            <li>Setup change to test different valve seal type <br /> in less than 3 minutes (patent pending)</li>
                                            <li>Independent enclosure</li>
                                        </ul>
                                    </div>
                                    <div className="crimg">
                                        <img src={ValvePressure} className="img-responsive" alt="Ball Valve Testbench" style={{ maxWidth: '100%' }} />
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item product-carousel-container container-fluid d-flex" style={{ alignItems: "center", justifyContent: "center" }}>
                                <div className="row d-flex flex-lg-row flex-sm-column" style={{ padding: "0 10rem", alignItems: "center", justifyContent: "space-around", flexDirection: "row", flexWrap: "nowrap", alignContent: "center" }}>
                                    <div className="textcr slide-padding">
                                        <h4>SMART SYSTEM FEATURES</h4>
                                        <h3 className="mb-5">Smart Console for Pressure Test Bay</h3>
                                        <ul>
                                            <li>Smart filling circuit</li>
                                            <li>Integration to existing water treatment plant</li>
                                            <li>Corrosion Inhibitor dosing mechanism</li>
                                            <li>Computerized multiple pump size management to ensure best energy efficiency</li>
                                            <li>Capability to execute predefined test sequences based on CMMS Status</li>
                                            <li>Multiple outputs to enable automated test sequences</li>
                                        </ul>
                                    </div>
                                    <div className="crimg">
                                        <img src={SmartConsolePTB} className="img-responsive" alt="Smart Console PTB" style={{ maxWidth: '80%' }} />
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item product-carousel-container container-fluid d-flex" style={{ alignItems: "center", justifyContent: "center" }}>
                                <div className="row d-flex flex-lg-row flex-sm-column" style={{ padding: "0 10rem", alignItems: "center", justifyContent: "space-around", flexDirection: "row", flexWrap: "nowrap", alignContent: "center" }}>
                                    <div className="textcr slide-padding">
                                        <h4>SMART SYSTEM FEATURES</h4>
                                        <h3 className="mb-5">Pipework Inspector</h3>
                                        <ul>
                                            <li>Patent pending mechanism</li>
                                            <li>Fully automated inspection and measurement</li>
                                            <li>Adaptable to multiple diameters</li>
                                            <li>Adjustable measuring resolution</li>
                                            <li>Automatic positioning based on diameter and length</li>
                                            <li>Automated visual inspection based on visual AI</li>
                                            <li>Asset Identification with Barcode / RFID reader</li>
                                            <li>No paint removal required</li>
                                        </ul>
                                    </div>
                                    <div className="crimg">
                                        <img src={PipeworkInspector} className="img-responsive" alt="Pipework Inspector" style={{ maxWidth: '100%' }} />
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item product-carousel-container container-fluid d-flex" style={{ alignItems: "center", justifyContent: "center" }}>
                                <div className="row d-flex flex-lg-row flex-sm-column" style={{ padding: "0 10rem", alignItems: "center", justifyContent: "space-around", flexDirection: "row", flexWrap: "nowrap", alignContent: "center" }}>
                                    <div className="textcr slide-padding">
                                        <h4>SMART SYSTEM FEATURES</h4>
                                        <h3 className="mb-5">Modular Pressure Test Bay</h3>
                                        <ul>
                                            <li>Smart filling circuit</li>
                                            <li>Integrated closed loop water recycling system</li>
                                            <li>Corrosion Inhibitor dosing circuit</li>
                                            <li>Tailored for specific components:</li>
                                            <li>Downhole Tools</li>
                                            <li>Sampling Bottles</li>
                                            <li>Chiksan loops</li>
                                            <li>WHE Risers and components</li>
                                            <li>Independent enclosure</li>
                                        </ul>
                                    </div>
                                    <div className="crimg">
                                        <img src={ModularPressure} className="img-responsive" alt="Modular Pressure Test Bay" style={{ maxWidth: '80%' }} />
                                    </div>
                                </div>
                            </div>

                        </div>



                        <button className="carousel-control-prev" type="button" data-bs-target="#carousel-products-two" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carousel-products-two" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SmartSystemsPage() {
    return (
        <div>
            <NavBar />
            <LandingPage />
            <SecondLanding />
            <ThirdLanding />
            <LandingAction title="SPARE PARTS" subtitle="PRODUCTS" cname="landing-replacement-parts">
                <p>In need of spare parts for your equipment ? IPREXYS also offer spare part supply in the best time and price. <br /> This additionally to our best-in-class Piping Inspectors and Smart Pressure Test consoles.</p>
                <a href="/products" className="landing-btn fill">SHOW OUR PRODUCTS</a>
            </LandingAction>
            <Footer />
        </div>
    );
}

export { SmartSystemsPage };