import React, { Fragment } from "react";

import { NavBar } from "Components/Navbar";
import { Footer } from "Components/Footer";
import { Separator } from "Components/Separator";

import scheme from "Assets/home/SCHEME_HOMPAGE.png";
import AnimatedLanding from "Components/Landing/LandingEffect";

function LandingPage() {
    return (
        <Fragment>
            <AnimatedLanding className="landing-special landing-full-size" title="Innovation for efficiency" id="landing-about-us">
                <p className="page-header-text">We are a Start-Up with automated solutions to
                    improve process efficiency <br /> and reduce costs
                    in the industrial sector.</p>
                <p className="on-board">GET ON BOARD</p>
                <i className="bi bi-arrow-down red-color"></i>
            </AnimatedLanding>
        </Fragment>
    )
}

function SecondLanding() {
    return (
        <Fragment>
            <AnimatedLanding className="landing-full-size" title="OUR OFFER" id="landing-digital-services">
                <a href="/our-offer" className="landing-btn-home fill">DISCOVER OUR OFFER</a>
            </AnimatedLanding>
        </Fragment>
    )
}

function ThirdLanding() {
    return (
        <Fragment>
            <div className="container-fluid text-center operating-cost radial-bg">
                <h3>TECHNOLOGY INTEGRATOR</h3>
                <Separator className="red-separator" width="5rem" height="2px" />
                <p className="">IPREXYS solutions integrate cutting-edge technologies to provide you solutions to improve your efficiency and reduce your operating costs.</p>
                <img className="raas-scheme" src={scheme} alt="OPERATING COST REDUCTIONS" />
            </div>
        </Fragment>
    )
}

function LandingOurProducts(props) {
    return (
        <Fragment>
            <AnimatedLanding className="landing-full-size" title="OUR PRODUCTS" id="landing-home-our-products">
                <a href="/products" className="landing-btn-home fill mt-3">SHOW OUR PRODUCTS</a>
            </AnimatedLanding>
        </Fragment>
    )
}

function LandingDigitalServices() {
    return (
        <Fragment>
            <AnimatedLanding className="landing-full-size" title="DIGITAL SERVICES" id="landing-home-ds">
                <a href="/digital-services" className="landing-btn-home fill mt-3">LEARN MORE</a>
            </AnimatedLanding>
        </Fragment>
    )
}

function LandingSmartSystems() {
    return (
        <Fragment>
            <AnimatedLanding title="SMART SYSTEM" id="landing-home-ss">
                <a href="/smart-systems" className="landing-btn-home fill mt-3">LEARN MORE</a>
            </AnimatedLanding>
        </Fragment>
    )
}

function HomePage() {
    return (
        <div>
            <NavBar />
            <LandingPage />
            <SecondLanding />
            <ThirdLanding />
            <LandingOurProducts />
            <LandingDigitalServices />
            <LandingSmartSystems />
            <Footer />
        </div>
    );
}

export { HomePage };