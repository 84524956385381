import React from "react";

function Footer() {

    return (
        <footer className="bd-footer footermain">
            <div className="container-fluid main-ft">
                <ul className="bd-footer-links text-center">
                    <li><small className="d-block">IPREXYS © 2022</small></li>
                    <li><a href="#">About</a></li>
                    <li><a href="#">Products</a></li>
                    <li><a href="#">Digital Services</a></li>
                    <li><a href="#">Smart System Advantages</a></li>
                    <li><a href="#">Contact</a></li>
                    <li><a href="#">Privacy Policy</a></li>
                </ul>
            </div>
        </footer>
    );
}

export { Footer };
