import React, { Fragment, useState } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

import { Landing } from "Components/Landing";
import { NavBar } from "Components/Navbar";
import { Footer } from "Components/Footer";
import { Separator } from "Components/Separator";

const FORM_ENDPOINT = "https://formspree.io/f/mknydryn";

function LandingPage() {
    return (
        <Fragment>
            <Landing title="CONTACT US" id="landing-contact-us">
                <p className="page-header-text mb-5">For all inquiries, please email us using the form below.</p>
            </Landing>
        </Fragment>
    )
}

function SecondLanding() {

    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = () => {
        setTimeout(() => {
            setSubmitted(true);
        }, 100);
    };

    return (
        <Fragment>
            <div className="container-fluid" style={{ marginTop: "5rem", marginBottom: "5rem" }}>
                <div className="row">
                    <div className="col-sm-12 col-md-4 contact-box">
                        <h2>A QUESTION ?</h2>
                        <h2 className="fw-bold">SEND US A MESSAGE</h2>
                        <Separator className="mb-5 mt-3" width="8.2rem" height=".15rem" />
                        <div className="row">
                            <div className="col-1">
                                <i className="bi bi-telephone-fill"></i>
                            </div>
                            <div className="col-11">
                                <p className="ps-3">(+33) 6 40 44 98 48</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <i className="bi bi-envelope-fill"></i>
                            </div>
                            <div className="col-11">
                                <p className="ps-3">273 Rue de Seine <br />
                                    77000, Vaux-le-Pénil, France.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <i className="bi bi-at"></i>
                            </div>
                            <div className="col-11">
                                <p className="ps-3">contact@iprexys.com</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12 text-center fs-1">
                                <i className="bi bi-instagram mx-3"></i>
                                <i className="bi bi-facebook mx-3"></i>
                                <i className="bi bi-linkedin mx-3"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        <div className="contact-form">
                            {submitted === false ?
                                <form action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST" target="_blank">
                                    <div className="mb-3">
                                        <label htmlFor="fullNameInput" className="form-label">Full name</label>
                                        <input type="text" name="fullname" className="form-control" id="fullNameInput" aria-describedby="fullNameHelp" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="companyInput" className="form-label">Company</label>
                                        <input type="text" name="company" className="form-control" id="companyInput" aria-describedby="companyHelp" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="emailInput" className="form-label">Email adress</label>
                                        <input type="email" name="mail" className="form-control" id="emailInput" aria-describedby="emailHelp" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phoneInput" className="form-label">Phone number</label>
                                        <input type="tel" name="phone" className="form-control" id="phoneInput" aria-describedby="phoneHelp" pattern="[+0-9 ]+" required />
                                    </div>
                                    <div>
                                        <label htmlFor="requestInput" className="form-label">Request</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name="piping-inspector" id="inlineCheckbox1" value="option1" />
                                        <label className="form-check-label" htmlFor="inlineCheckbox1">Piping inspector</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name="pressure-test" id="inlineCheckbox2" value="option2" />
                                        <label className="form-check-label" htmlFor="inlineCheckbox2">Pressure test</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name="digital-services" id="inlineCheckbox3" value="option3" />
                                        <label className="form-check-label" htmlFor="inlineCheckbox3">Digital services</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name="smart-system" value="option4" />
                                        <label className="form-check-label" htmlFor="inlineCheckbox4">Smart system</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" name="others" id="inlineCheckbox5" value="option5" />
                                        <label className="form-check-label" htmlFor="inlineCheckbox5">Others</label>
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="messageTextArea1" className="form-label">Message</label>
                                        <textarea className="form-control" name="messageTextArea1" id="messageTextArea1" rows={5}></textarea>
                                    </div>
                                    <br />
                                    <button type="submit" className="nlanding-btn nfill mx-auto mt-3">SEND MESSAGE</button>
                                </form>
                                :
                                <div className="text-center">
                                    <h2>THANKS FOR YOUR MESSAGE !</h2>
                                    <p>We'll be in touch soon</p>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function Contact() {
    return (
        <div>
            <NavBar />
            <LandingPage />
            <SecondLanding />
            <Footer />
        </div>
    );
}

export { Contact };