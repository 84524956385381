import React, { Component } from 'react';
import handleViewport from 'react-in-viewport';

class LandingEffect extends Component {
    getStyle() {
        const { inViewport, enterCount } = this.props;

        if (inViewport && enterCount >= 1) {
            return "background-zoom-in";
        } else if (!inViewport && enterCount < 1) {
            return "background-zoom-out"
        } 
        else if(inViewport && enterCount < 1) {
            return "background-zoom-in";
        }
        else {
            return "";
        }

    }

    getTitleStyle() {
        const { inViewport, enterCount } = this.props;

        if (inViewport && enterCount > 1) {
            return "title-fade-slide-in";
        } else if (!inViewport && enterCount < 1) {
            return "title-fade-slide-out";
        } else {
            return "";
        }
    }

    render() {
        const { enterCount, leaveCount } = this.props;
        return (
            <div className={`container-fluid landing-bg-wrap ${this.props.className || ""} ${this.getStyle() || ""}`} id={this.props.id || ""} >
                <div className="container content-wrap">
                    <div className="row align-items-center landing">
                        <div className="col-12 text-center">
                            <h1 className={`page-header-title ${this.getTitleStyle() || ""}`}>{this.props.title}</h1>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const AnimatedLanding = handleViewport(LandingEffect, { rootMargin: '-1.0px' });

export default AnimatedLanding;