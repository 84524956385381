import { Landing, LandingAction } from "../../Components/Landing";
import React, { Fragment } from "react";
import scheme from "Assets/digital_services/scheme_Raas.png";
import { NavBar } from "Components/Navbar";
import { Footer } from "Components/Footer";
import { Separator } from "Components/Separator";

function LandingPage() {
    return (
        <Fragment>
            <Landing title="DIGITAL SERVICES" id="landing-digital-services">
                <p className="page-header-text mb-5">This is the core of our process</p>
            </Landing>
        </Fragment>
    )
}

function SecondLanding() {
    let style1 = {
        padding: '5rem'
    }

    return (
        <Fragment>
            <div className="container-fluid" style={{ marginTop: ".5rem", marginBottom: ".5rem" }} id="raas">
                <div className="row">
                    <div className="col-sm-12 col-md-8 infoBox" style={style1}>
                        <h3>REPORTING AS A SERVICE (RaaS)</h3>
                        <Separator className="red-text-bottom" width="23%" height="2px" />
                        <p>Our secured Cloud based platform allow manage the full cycle for the data generate by our Smart Systems, our service is all-inclusive approach allowing our customers focus on their core business activities.
                            <br /><br />
                            Additionally, the service includes the access to Management Dashboard which provides data analytics insights based in Artificial Intelligence engine to support Continuous Process Improvement in Real Time.
                        </p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

function ThirdLanding() {

    return (
        <Fragment>
            <div className="container-fluid text-center operating-cost radial-bg" style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
                <h3>OUR RaaS OFFER</h3>
                <hr className="red-separator" />
                <img className="raas-scheme" src={scheme} alt="OPERATING COST REDUCTIONS" />
            </div>
        </Fragment>
    )
}

function DigitalServicesPage() {
    return (
        <div>
            <NavBar />
            <LandingPage />
            <SecondLanding />
            <ThirdLanding />
            <LandingAction title="DISCOVER OUR TECHNOLOGIES" subtitle="SMART SYSTEM" cname="landing-smart-system">
                <p>We offer a system that will significantly improve your process<br /> in 5 keys areas: security, data, environmental, cost reduction and process.</p>
                <a href="/smart-systems" className="landing-btn fill">LEARN MORE</a>
            </LandingAction>
            <Footer />
        </div>
    );
}

export { DigitalServicesPage };