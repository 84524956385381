import {Landing, LandingAction} from "../../Components/Landing";
import React, {Fragment} from "react";
import {NavBar} from "Components/Navbar";
import {Footer} from "Components/Footer";

import ChiksanUn from "Assets/our_products/CHIKSAN/Chiksan_Loop_1.png"
import ChiksanDeux from "Assets/our_products/CHIKSAN/Chiksan_Loop_2.png"
import ChiksanTrois from "Assets/our_products/CHIKSAN/Chiksan_Loop_3.png"
import ChiksanQuatre from "Assets/our_products/CHIKSAN/Chiksan_Loop_4.png"
import ChiksanCinq from "Assets/our_products/CHIKSAN/Chiksan_Loop_5.png"
import ChiksanSix from "Assets/our_products/CHIKSAN/CHIKSAN_6.png"
import Elbows from "Assets/our_products/Elbows@2x.png"

import StraightPipeUn from "Assets/our_products/STRAIGHT-PIPE/Straight_Pipe_1.png"
import StraightPipeDeux from "Assets/our_products/STRAIGHT-PIPE/Straight_Pipe_2.png"
import StraightPipeTrois from "Assets/our_products/STRAIGHT-PIPE/Straight_Pipe_3.png"
import StraightPipeQuatre from "Assets/our_products/STRAIGHT-PIPE/Straight_Pipe_4.png"
import StraightPipeCinq from "Assets/our_products/STRAIGHT-PIPE/Straight_Pipe_5.png"
import StraightPipeVideo from "Assets/our_products/STRAIGHT-PIPE/STRAIGHTPIPE_6.mp4"

import BallValve from "Assets/our_products/ball_valve_testbench@2x.png"
import GateValve1 from "Assets/our_products/GATE_VALVE_PRESSURE/GATE_VALVE_TESTBENCH_1.png"
import GateValve2 from "Assets/our_products/GATE_VALVE_PRESSURE/GATE_VALVE_TESTBENCH_2.png"
import GateValve3 from "Assets/our_products/GATE_VALVE_PRESSURE/GATE_VALVE_TESTBENCH_3.png"
import GateValve4 from "Assets/our_products/GATE_VALVE_PRESSURE/GATE_VALVE_TESTBENCH_4.png"
import GateValve5 from "Assets/our_products/GATE_VALVE_PRESSURE/GATE_VALVE_TESTBENCH_5.png"

import SmartConsolePTB from "Assets/our_products/smart_console_for_pressure_test_bay@2x.png"

function LandingPage() {
    return (
        <Fragment>
            <Landing title="OUR PRODUCTS" id="landing-our-products">
                <p className="page-header-text mb-5">- Automated Pipe Inspection Systems <br/>
                    - Smart Pressure Test Consoles with connection to Cloud</p>
            </Landing>
        </Fragment>
    )
}

function SecondLanding() {

    return (
        <div className="container-fluid landing-carousel-white mb-2" id="piping">
            <div className="row">
                <div className="col-12 text-center">
                    <div id="carousel-products" className="carousel slide carousel-fade" data-bs-ride="carousel"
                         data-bs-interval="false">
                        <div className="carousel-inner">
                            <div className="carousel-item active product-carousel-container">
                                <h2>Chiksan Loop</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={ChiksanUn} className="product-carousel-img" alt="Chiksan 1"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Chiksan Loop</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={ChiksanDeux} className="product-carousel-img" alt="Chiksan 2"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Chiksan Loop</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={ChiksanTrois} className="product-carousel-img" alt="Chiksan 3"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Chiksan Loop</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={ChiksanQuatre} className="product-carousel-img" alt="Chiksan 4"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Chiksan Loop</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={ChiksanCinq} className="product-carousel-img" alt="Chiksan 5"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Straight Pipe</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={StraightPipeUn} className="product-carousel-img" alt="Straight Pipe 1"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Straight Pipe</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={StraightPipeDeux} className="product-carousel-img" alt="Straight Pipe 1"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Straight Pipe</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={StraightPipeTrois} className="product-carousel-img" alt="Straight Pipe 1"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Straight Pipe</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={StraightPipeQuatre} className="product-carousel-img" alt="Straight Pipe 1"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Straight Pipe</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <img src={StraightPipeCinq} className="product-carousel-img" alt="Straight Pipe 1"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Straight Pipe</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <div className="ratio ratio-21x9">
                                    <video width="320" height="240" controls={true} autoPlay={true} muted={true}>
                                        <source src={StraightPipeVideo} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Elbows</h2>
                                <h5>PIPING INSPECTOR</h5>
                                <h2 style={{"marginTop": "5%"}}><strong>3D MODELS</strong></h2>
                                <h2 style={{"color": "red"}}><strong>- Under Request -</strong></h2>
                            </div>
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#carousel-products"
                                data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carousel-products"
                                data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ThirdLanding() {

    return (
        <div className="container-fluid landing-carousel-white mb-2 mt-2" id="pressure">
            <div className="row">
                <div className="col-12 text-center">
                    <div id="carousel-products-two" className="carousel slide carousel-fade" data-bs-ride="carousel"
                         data-bs-interval="false">
                        <div className="carousel-inner">
                            <div className="carousel-item active product-carousel-container">
                                <h2>Ball Valve Testbench</h2>
                                <h5>PRESSURE TEST</h5>
                                <h2 style={{"marginTop": "5%"}}><strong>3D MODELS</strong></h2>
                                <h2 style={{"color": "red"}}><strong>- coming soon-</strong></h2>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Gate Valve Testbench</h2>
                                <h5>PRESSURE TEST</h5>
                                <img src={GateValve1} className="product-carousel-img" alt="Chiksan Loop"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Gate Valve Testbench</h2>
                                <h5>PRESSURE TEST</h5>
                                <img src={GateValve2} className="product-carousel-img" alt="Chiksan Loop"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Gate Valve Testbench</h2>
                                <h5>PRESSURE TEST</h5>
                                <img src={GateValve3} className="product-carousel-img" alt="Chiksan Loop"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Gate Valve Testbench</h2>
                                <h5>PRESSURE TEST</h5>
                                <img src={GateValve4} className="product-carousel-img" alt="Chiksan Loop"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Gate Valve Testbench</h2>
                                <h5>PRESSURE TEST</h5>
                                <img src={GateValve5} className="product-carousel-img" alt="Chiksan Loop"/>
                            </div>

                            <div className="carousel-item product-carousel-container">
                                <h2>Smart Console for PTB</h2>
                                <h5>PRESSURE TEST</h5>
                                <h2 style={{"marginTop": "5%"}}><strong>3D MODELS</strong></h2>
                                <h2 style={{"color": "red"}}><strong>- Under Request -</strong></h2>
                            </div>
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#carousel-products-two"
                                data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carousel-products-two"
                                data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function ProductsPage() {
    return (
        <div>
            <NavBar/>
            <LandingPage/>
            <SecondLanding/>
            <LandingAction title="DISCOVER OUR TECHNOLOGIES" subtitle="SMART SYSTEM" cname="landing-smart-system">
                <p>We offer a system that will significantly improve your process in 5 keys areas: security, data,
                    environmental, cost reduction and process.</p>
                <a href="/smart-systems" className="landing-btn fill">LEARN MORE</a>
            </LandingAction>
            <ThirdLanding/>
            <LandingAction title="Reporting as a Service (RaaS)" subtitle="DIGITAL SERVICES" cname="landing-opc-reduc">
                <p>In order to support Continuous Process Improvement in real time, our all-inclusive services <br/>are
                    based in Artificial Intelligence engine, secured cloud platform and more.</p>
                <a href="/digital-services" className="landing-btn fill">LEARN MORE</a>
            </LandingAction>
            <Footer/>
        </div>
    );
}

export {ProductsPage};